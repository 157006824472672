import axiosCall from "../../api/axios";
import SignInImage from "../../components/SignInImage/SignInImage.vue";
import Loader from "../../components/Loader/Loader.vue";
import SignInBackground from "../../components/SignInBackground/SignInBackground.vue";
import {
  required,email
} from "vuelidate/lib/validators";

export default {
  name: "Resetpasswordlink",
  components: {
    SignInImage,
    Loader,
    SignInBackground
  },
  data() {
    return {
      email: "",
      emailSentMessage: "",
      errorMessage: "",
      successMessageStatus: false,
      errorMessageStatus: false,
      isLoading: false
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    submit() {
      
      this.$v.$touch();
      let verifyObject = this.prepareRequestObject();
      if (verifyObject.emailId === "") {
        //this.errorMessage = "Please enter you email-id";
        this.errorMessageStatus = true;
        this.successMessageStatus = false;
        this.isLoading = false;
      } else {
        if (!this.$v.$invalid) {
          this.isLoading = true;
          axiosCall.postRequest(
            process.env.VUE_APP_HOST + "account/api/v1/forgot",
            verifyObject,
            (response) => {
              if (response.data.apiResponseStatus.code === 1000) {
                this.successMessageStatus = true;
                this.errorMessageStatus = false;
                this.emailSentMessage =
                  "Please check your Email to reset your password";
                this.isLoading = false;
              } else if (response.data.apiResponseStatus.code === 9014) {
                this.errorMessage =
                  "User is not registered with entered email-id";
                this.errorMessageStatus = true;
                this.successMessageStatus = false;
                this.isLoading = false;
              } else if (response.data.apiResponseStatus.code !== 1000) {
                this.errorMessage = response.data.apiResponseStatus.message;
                this.errorMessageStatus = true;
                this.successMessageStatus = false;
                this.isLoading = false;
              }
            }
          );
        }
      }
    },

    resend() {
      if (this.successMessageStatus) {
        this.submit();
      }
    },

    prepareRequestObject() {
      let verifyObject = {
        emailId: this.email
      };
      return verifyObject;
    }
  }
};
