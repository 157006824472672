import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { Button, Input, Select } from "iview";

export default {
  name: "Tiptap",
  components: {
    Button,
    Input,
    Select,
    quillEditor,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);    
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  data: () => ({
    window:{
        width:0,
        height:0
    } ,
    delta: undefined,
    content: "",
    htmltext: undefined,
    editorOption: {
      debug: "info",
      placeholder: "Start writing here",
      readOnly: false,
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"], 
            ["code-block", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6] }],
            ["link"],
            [{ color: [] }, { background: [] },],
            [{ align: [] }],
            ["clean","image"],
          ],
        },
      },
      theme: "snow",
    },
  }),
};