import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import { mapGetters } from "vuex";
import Loader from "../../components/Loader/Loader.vue";
import PopUp from "../../components/PopUp/PopUp.vue";
import SideMenu from "../../components/SideMenu/SideMenu.vue";

export default {
  name: "view-profile-page",
  components: {
    SideMenu,
    Loader,
    PopUp,
    VueCropper
  },
  props: [],
  data() {
    return {
      profile: this.$store.state.profile,
      profilePhoto: File,
      profilePhotoUrl: "",
      imageChanged: false,
      photoEditClicked: false,
      checkedExpertiseId: [],
      checkedExp: [],
      influencerName: "",
      firstName: "",
      lastName: "",
      errors: [],
      cropImg: "",
      achievements: [],
      formEditClicked: false,
      serviceValueErrors: [],
      serviceNameErrors:[],
      saveMessage:
        "Your data has been saved successfully and will be available on your personalized leadersHum page shortly.",
      saveMessageStatus: true,
      isNameField: {
        error: "false",
        errorTxt: ""
      },
      isTitleField: {
        error: "false",
        errorTxt: ""
      },
      isEmailField: {
        error: "false",
        errorTxt: ""
      },
      isOrganisationField: {
        error: "false",
        errorTxt: ""
      },
      isTaglineField: {
        error: "false",
        errorTxt: ""
      },
      isPhoneField: {
        error: "false",
        errorTxt: ""
      },
      isYearField: {
        error: "false",
        errorTxt: ""
      },
      isBioField: {
        error: "false",
        errorTxt: ""
      },
      isPhotoField: {
        error: "false",
        errorTxt: ""
      },
      isLinkedinField: {
        error: "false",
        errorTxt: ""
      },
      isInstagramField: {
        error: "false",
        errorTxt: ""
      },
      isTwitterField: {
        error: "false",
        errorTxt: ""
      },
      isWebsiteField: {
        error: "false",
        errorTxt: ""
      },
      isServiceNameField: {
        error: "false",
        errorTxt: ""
      },
      isServiceValueField: {
        error: "false",
        errorTxt: ""
      },
      photoName: "",
      photoType: "",
      window: {
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      expertiseInfo: "expertiseInfo",
      influencerExpertise: "influencerExpertise",
      influencerExpertiseId: "influencerExpertiseId",
      getProfileUrl: "getProfileUrl",
      getLoader: "getLoader",
      getConfirmMessage: "getConfirmMessage"
    })
  },
  watch: {
    "$store.state.profile.expertise": {
      deep: true,
      handler(newVal) {
        this.setInfluencerExpertise();
      }
    },
    "$store.state.profile.firstName": {
      deep: true,
      handler(newVal) {
        this.influencerName =
          this.$store.state.profile.firstName +
          " " +
          this.$store.state.profile.lastName;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    // window.location.reload();
    var start = 1900;
    var end = new Date().getFullYear();
    var options = "";
    for (var year = start; year <= end; year++) {
      options += "<option>" + year + "</option>";
    }
    document.getElementById("birthyr").innerHTML = options;
    this.getProfileInfo();
    
    this.getAllExpertise();
    this.setInfluencerName();
  },
  methods: {
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    setInfluencerName() {
      this.influencerName =
        this.$store.state.profile.firstName +
        " " +
        this.$store.state.profile.lastName;
    },
    getProfileInfo() {
      let currentCookie = JSON.parse(this.getCookie("User"));
      return this.$store.dispatch("getProfileInfo");
    },
    getAllExpertise() {
      return this.$store.dispatch("getAllExpertise");
    },
    setInfluencerExpertise() {
      this.checkedExpertiseId = this.influencerExpertiseId;
    },
    updateExpertise(event, expertise) {
      event.stopPropagation();
      this.checkedExpertiseId = this.checkedExpertiseId.filter(function(
        element
      ) {
        return element !== undefined;
      });
      this.$store.commit("setInfluencerExpertiseId", this.checkedExpertiseId);
    },
    addServiceRow(event, index) {
      event.preventDefault();
      let tempService = {
        serviceDesc: "",
        serviceName: "",
        serviceValue: ""
      };
      this.profile.services.push(tempService);
      this.formEditClicked = true;
    },
    deleteServiceRow(event, index) {
      event.preventDefault();
      this.profile.services.splice(index, 1);
      this.formEditClicked = true;
    },
    isPhotoEdited() {
      if (this.imageChanged == false) {
        this.photoEditClicked = false;
      } else {
        this.photoEditClicked = true;
      }
    },
    onFileSelected(event) {
      if (event.target.files && event.target.files.length) {
        this.photoUploadError = false;
        this.profilePhoto = event.target.files[0];
        this.profilePhotoUrl = URL.createObjectURL(event.target.files[0]);
        this.photoName = event.target.files[0].name;
        this.photoType = event.target.files[0].type;
        this.imageChanged = true;
        this.savedImage = true;
        this.imageChangedEvent = event;
      }
    },
    cropImage() {
      this.cropImg = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");
      var byteString = atob(this.cropImg.split(",")[1]);
      var mimeString = this.cropImg
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }
      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], { type: mimeString });
      var file = new File([blob], this.photoName, { type: this.photoType });
      this.profile.photo = file;
    },
    isValidProfileData(event) {
      this.errors = [];
      if (
        !this.influencerName ||this.influencerName.trim().length===0 ||
        !this.validateTextInput(this.influencerName)
      ) {
        this.isNameField.error = true;
        this.isNameField.errorTxt = !this.influencerName
          ? "Name is required": this.influencerName.trim().length===0 ? "Name is required"
          : "Please enter Valid Name";
        this.errors.push("Name error");
      } else {
        this.isNameField.error = false;
        this.isNameField.errorTxt = "";
      }
      if (
        !this.profile.email ||this.profile.email.trim().length===0 ||
        !this.validEmail(this.sanitizeString(this.profile.email))
      ) {
        this.errors.push("Title error");
        this.isEmailField.error = true;
        this.isEmailField.errorTxt = !this.profile.email
          ? "Email is required": this.profile.email.trim().length===0 ? "Email is required"
          : "Please enter valid email";
      } else {
        this.isEmailField.error = false;
        this.isEmailField.errorTxt = "";
      }
      if (
        !this.profile.title || this.profile.title.trim().length===0 ||
        !this.validateTextInput(this.sanitizeString(this.profile.title))||this.profile.title.length>20
      ) {
        this.errors.push("Title error");
        this.isTitleField.error = true;
        this.isTitleField.errorTxt = !this.profile.title
          ? "Title is required" :this.profile.title.trim().length===0 ? "Title is required"
          : !this.validateTextInput(this.sanitizeString(this.profile.title))?"Please enter valid text":"Title can have atmost 20 characters";
      } else {
        this.isTitleField.error = false;
        this.isTitleField.errorTxt = "";
      }
      if (
        !this.profile.organisation ||this.profile.organisation.trim().length===0 ||
        !this.validateTextInput(this.sanitizeString(this.profile.organisation))||this.profile.organisation.length>50
      ) {
        this.errors.push("Org error");
        this.isOrganisationField.error = true;
        this.isOrganisationField.errorTxt = !this.profile.organisation
          ? "Organisation is required" : this.profile.organisation.trim().length===0 ? "Organisation is required"
          : !this.validateTextInput(this.sanitizeString(this.profile.organisation))? "Please enter valid text":"Organisation can have atmost 50 characters";
      } else {
        this.isOrganisationField.error = false;
        this.isOrganisationField.errorTxt = "";
      }
      if (
        !this.profile.tagLine ||this.profile.tagLine.trim().length===0 ||
        !this.validateTextInput(this.sanitizeString(this.profile.tagLine))|| this.profile.tagLine.length>20
      ) {
        this.errors.push("Org error");
        this.isTaglineField.error = true;
        this.isTaglineField.errorTxt = !this.profile.tagLine
          ? "Tagline is required" : this.profile.tagLine.trim().length===0 ? "Tagline is required"
          : !this.validateTextInput(this.sanitizeString(this.profile.tagLine))? "Please enter valid text":"Tagline can have atmost 20 characters";
      } else {
        this.isTaglineField.error = false;
        this.isTaglineField.errorTxt = "";
      }
      if (
        !this.profile.phoneNumber ||this.profile.phoneNumber.trim().length===0 ||
        !this.validatePhoneNumber(this.sanitizeString(this.profile.phoneNumber))
      ) {
        this.errors.push("Org error");
        this.isPhoneField.error = true;
        this.isPhoneField.errorTxt = !this.profile.phoneNumber
          ? "Phone Number is required": this.profile.phoneNumber.trim().length===0 ? "Phone Number is required"
          : "Please enter a valid phone number";
      } else {
        this.isPhoneField.error = false;
        this.isPhoneField.errorTxt = "";
      }
      if (!this.profile.yob) {
        this.errors.push("Org error");
        this.isYearField.error = true;
        this.isYearField.errorTxt = "Please select an year";
      } else {
        this.isYearField.error = false;
        this.isYearField.errorTxt = "";
      }
      if (
        !this.profile.bio || this.profile.bio.length < 400 || this.profile.bio.length>2000 ||this.profile.bio.trim().length===0 ||
        !this.validateBioTextInput(this.sanitizeString(this.profile.bio))
      ) {
        this.errors.push("Bio error");
        let errorBioTxt ="Bio must have atleast 400 characters and atmost 2000 characters" + "<br>" +  "Please check the text, special characters supported by us are : _@./\\#&'*?!“”`’‘\",+$%^()={}[]-:;|<>~";
        this.isBioField.error = true;
        this.isBioField.errorTxt = !this.profile.bio
          ? "Bio is required": this.profile.bio.trim().length===0 ? "Bio is required"
            :(!this.validateBioTextInput(this.sanitizeString(this.profile.bio)) && (this.profile.bio.length < 400 || this.profile.bio.length>2000))? errorBioTxt:
            !this.validateBioTextInput(this.sanitizeString(this.profile.bio)) ? "Please check the text, special characters supported by us are : _@./\\#&'*?!“”`’‘\",+$%^()={}[]-:;|<>~" : "Bio must have atleast 400 characters and atmost 2000 characters";
      } else {
        this.isBioField.error = false;
        this.isBioField.errorTxt = "";
      }
      if (
        !this.validateLinkedinUrl(this.sanitizeString(this.profile.linkedinUrl))
      ) {
        this.errors.push("LinkedIn Url error");
        this.isLinkedinField.error = true;
        this.isLinkedinField.errorTxt = "Please enter valid url";
      } else {
        this.isLinkedinField.error = false;
        this.isLinkedinField.errorTxt = "";
      }
      if (
        !this.validateInstagram(this.sanitizeString(this.profile.instagramUrl))
      ) {
        this.errors.push("Instagram Url error");
        this.isInstagramField.error = true;
        this.isInstagramField.errorTxt = "Please enter valid url";
      } else {
        this.isInstagramField.error = false;
        this.isInstagramField.errorTxt = "";
      }
      if (!this.validateTwitter(this.sanitizeString(this.profile.twitterUrl))) {
        this.errors.push("Twitter Url error");
        this.isTwitterField.error = true;
        this.isTwitterField.errorTxt = "Please enter valid url";
      } else {
        this.isTwitterField.error = false;
        this.isTwitterField.errorTxt = "";
      }
      if (!this.validateWebsite(this.sanitizeString(this.profile.websiteUrl))) {
        this.errors.push("Website Url error");
        this.isWebsiteField.error = true;
        this.isWebsiteField.errorTxt = "Please enter valid url";
      } else {
        this.isWebsiteField.error = false;
        this.isWebsiteField.errorTxt = "";
      }
      if (!this.profile.profileUrl || (this.profile.profileUrl==='https://staticcdn-prod.peoplehum.com/static_resources/images/Default-Photo.jpeg' && !this.photoEditClicked)) {
        this.errors.push("Photo error");
        this.isPhotoField.error = true;
        this.isPhotoField.errorTxt = "Profile photo is required";
      } else {
        if (this.photoEditClicked && !this.cropImg) {
          this.errors.push("Photo error");
          this.isPhotoField.error = true;
          this.isPhotoField.errorTxt = "Profile photo must be cropped";
        } else {
          this.isPhotoField.error = false;
          this.isPhotoField.errorTxt = "";
        }
      }
      if (!this.errors.length) {
        return true;
      }
      event.preventDefault();
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateTextInput(txt) {
      var re = /^[ A-Za-z0-9_@./#&'"*?!“”`’‘,+-]*$/;
      return re.test(txt);
    },
    validateBioTextInput(txt) {
      // var re = /^[ \nA-Za-z0-9_@./#&'"*?!“”`’‘,+$%^()={}[\]\-:;|–<>~]*$/;
      // return re.test(txt);
      return true;
    },
    sanitizeString(str) {
      if (str != null) {
        str = str.replace(/<\/?script>/gi, "");
        return str.trim();
      }
    },
    validatePhoneNumber(phno) {
      var re = /\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;
      return re.test(phno);
    },
    validateLinkedinUrl(txt) {
      if (txt) {
        var re = /(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/in\/([\w\-\_À-ÿ%]+)\/?/;
        return re.test(txt);
      } else {
        return true;
      }
    },
    validateInstagram(txt) {
      if (txt) {
        var re = /(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?\/?)/;
        return re.test(txt);
      } else {
        return true;
      }
    },
    validateTwitter(txt) {
      if (txt) {
        var re = /(?:https?:)?\/\/(?:[A-z]+\.)?twitter\.com\/@?(?!home|share|privacy|tos)([A-z0-9_]+)\/?/;
        return re.test(txt);
      } else {
        return true;
      }
    },
    validateWebsite(txt) {
      if (txt) {
        var re = /(?:https?:)?\/\/(?:www\.)?[a-zA-Z0-9@:%._\\+~#?&/=]{2,256}.[a-z]{2,6}([a-zA-Z0-9@:%._\\+~#?&/=]*)/;
        return re.test(txt);
      } else {
        return true;
      }
    },
    updateInfluencerName(influencerName) {
      let temp = this.sanitizeString(influencerName);
      let tempstr = temp.split(" ");
      if (tempstr.length > 1) {
        this.profile.firstName = temp.substr(0, temp.indexOf(" "));
        this.profile.lastName = temp.substr(temp.indexOf(" ") + 1);
      } else {
        this.profile.firstName = temp;
        this.profile.lastName = "";
      }
      this.$store.commit("setFirstAndLastName", this.profile);
    },
    cancelProfileEdit(event) {
      event.preventDefault();
      this.photoEditClicked = false;
      this.formEditClicked = false;
      this.imageChanged = false;
      this.$router.go(0);
      this.getProfileInfo();
    },
    saveProfileInfo(event) {
      this.serviceValueErrors = [];
      this.serviceNameErrors = [];
      this.profile.achievements = [
        this.profile.achievements1,
        this.profile.achievements2,
        this.profile.achievements3
      ];
      let priceRegex = /^[0-9]\d{0,6}\.?\d{1,2}$/;
      for (var i = 0; i < this.profile.services.length; i++) {
        if (this.profile.services[i].serviceName.trim().length == 0) {
          if (this.profile.services[i].serviceValue.trim().length == 0) {
            this.profile.services.splice(i, 1);
          }
          else {
            this.serviceNameErrors.push(i);
          }
        }
        if (this.profile.services.length>0 && this.profile.services[i].serviceValue && !priceRegex.test(this.profile.services[i].serviceValue)) {
          this.serviceValueErrors.push(i);
        }
      }
      let influenceProfileInfo = {
        id: this.profile.id,
        bio: this.profile.bio,
        twitterUrl: this.profile.twitterUrl,
        facebookUrl: this.profile.facebookUrl,
        googleUrl: this.profile.googleUrl,
        linkedinUrl: this.profile.linkedinUrl,
        instagramUrl: this.profile.instagramUrl,
        websiteUrl: this.profile.websiteUrl,
        expertise: this.checkedExpertiseId,
        services: this.profile.services,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        profileUrl: this.profile.profileUrl,
        tagLine: this.profile.tagLine,
        title: this.profile.title,
        phoneNumber: this.profile.phoneNumber,
        organisation: this.profile.organisation,
        yob: this.profile.yob,
        achievements: this.profile.achievements,
        mailId: this.profile.email
      };
     
      if (this.isValidProfileData(event) && this.serviceValueErrors.length===0 && this.serviceNameErrors.length===0 ) {
        if (this.photoEditClicked) {
          influenceProfileInfo.photo = this.profilePhoto;
          this.photoEditClicked = false;
          this.formEditClicked = false;
          this.$store.dispatch("getPresignedUrlForImage", influenceProfileInfo);
        } else {
          this.profile.firstName = this.firstName;
          this.profile.lastName = this.lastName;
          this.photoEditClicked = false;
          this.formEditClicked = false;
          this.$store.commit("setProfileInfo", influenceProfileInfo);
          this.$store.commit(
            "setInfluencerExpertiseId",
            this.checkedExpertiseId
          );
          this.$store.dispatch("saveProfileInfo");
        }
      } else {
        window.scrollTo(0, 0);
        if (this.profile.services.length === 0) {
          let tempService = {
            serviceDesc: "",
            serviceName: "",
            serviceValue: ""
          };
          this.profile.services.push(tempService);
        }
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
