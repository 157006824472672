var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delegate-page"},[_c('div',{staticClass:"body-section"},[(_vm.window.width > 1099)?_c('SideMenu'):_vm._e(),_c('div',{staticClass:"white-block"},[_c('div',{staticClass:"page-container-section"},[_c('div',{staticClass:"invite-info-section"},[_vm._m(0),_c('div',[_c('div',{staticClass:"block-name invite-txt"},[_vm._v(" Please enter email Id of the person you want to delegate ")]),_c('form',{staticClass:"delegate-form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.delegateEmail),expression:"delegateEmail"}],staticClass:"email-text",attrs:{"type":"email","id":"delegateEmail","name":"delegateEmail","autocomplete":"off","placeholder":"Jim.Brady@gmail.com"},domProps:{"value":(_vm.delegateEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.delegateEmail=$event.target.value}}}),(
                  !_vm.$v.delegateEmail.required &&
                  _vm.$v.delegateEmail.$dirty &&
                  _vm.window.width <= 767 &&
                  _vm.window.width >= 320
                )?_c('div',{staticClass:"invalid"},[_vm._v(" Email is required! ")]):_vm._e(),(
                  !_vm.$v.delegateEmail.email &&
                  _vm.$v.delegateEmail.$dirty &&
                  _vm.window.width <= 767 &&
                  _vm.window.width >= 320
                )?_c('div',{staticClass:"invalid"},[_vm._v(" Valid Email is required! ")]):_vm._e(),_c('button',{staticClass:"delegate-confirm",on:{"click":function($event){$event.preventDefault();return _vm.addDelegate.apply(null, arguments)}}},[_vm._v(" Confirm ")])]),(
                !_vm.$v.delegateEmail.required &&
                _vm.$v.delegateEmail.$dirty &&
                _vm.window.width > 767
              )?_c('div',{staticClass:"invalid"},[_vm._v(" Email is required! ")]):_vm._e(),(
                !_vm.$v.delegateEmail.email &&
                _vm.$v.delegateEmail.$dirty &&
                _vm.window.width > 767
              )?_c('div',{staticClass:"invalid"},[_vm._v(" Valid Email is required! ")]):_vm._e()]),(_vm.delegates.length !== 0 || _vm.assistants.length !== 0)?_c('div',{staticClass:"remove-delegate-section"},[_c('h4',{staticClass:"block-name invite-txt"},[_vm._v(" You have delegated the following to access your LeadersHum profile ")]),_c('div',{staticClass:"delegate-list"},_vm._l((_vm.assistants),function(assistant,index){return _c('div',{key:index,staticClass:"delegate-list-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(assistant.mailId),expression:"assistant.mailId"}],staticClass:"email-text",attrs:{"type":"email","placeholder":"Jim.Brady@gmail.com","autocomplete":"off","readonly":""},domProps:{"value":(assistant.mailId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(assistant, "mailId", $event.target.value)}}}),_c('button',{staticClass:"delegate-confirm",on:{"click":function($event){$event.preventDefault();return _vm.removeDelegate(assistant.id, true)}}},[_vm._v(" Remove ")]),_c('button',{staticClass:"delegate-accepted"},[_vm._v("Accepted")])])}),0),_c('div',{staticClass:"delegate-list"},_vm._l((_vm.delegates),function(delegate,index){return _c('div',{key:index,staticClass:"delegate-list-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(delegate.mailId),expression:"delegate.mailId"}],staticClass:"email-text",attrs:{"type":"email","placeholder":"Jim.Brady@gmail.com","autocomplete":"off","readonly":""},domProps:{"value":(delegate.mailId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(delegate, "mailId", $event.target.value)}}}),_c('button',{staticClass:"delegate-confirm",on:{"click":function($event){$event.preventDefault();return _vm.removeDelegate(delegate.id, false)}}},[_vm._v(" Remove ")])])}),0)]):_vm._e()])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invite-info"},[_c('div',[_vm._v(" By delegating you are choosing to give full access of your Leadershum account to that person. ")]),_c('div',[_vm._v("The editor can post/edit/delete content on your behalf.")]),_c('div',[_vm._v(" Please ensure that the choosen editor is a known and trusted person to you. ")]),_c('div',[_vm._v(" You can revoke these rights whenever you want in future. ")])])}]

export { render, staticRenderFns }