export default {
  name: "AddContentPreview",
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      content: {},
      firstName: "",
      lastName: "",
      mutablePreview: true,
      videoData: "",
      videoDraft: false,
      ytEmbedUrl:"",
      vimeoId: null,
      imageData: "",
      categoryName: "",
      tagList: [],
      blogImage: "",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      },
      assistant: null
    };
  },
  props: ["preview", "previewContent", "contentType"],
  mounted() {
    this.firstName = localStorage.getItem("firstName");
    this.lastName = localStorage.getItem("lastName");
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    closeModal() {
      this.mutablePreview = false;
      this.$emit("changePreview", this.mutablePreview);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.content = this.previewContent;
    this.contentUrl = this.content.contentUrl;
    this.tagList = this.content.tags;
    this.categoryName = this.content.categories[0].name.toUpperCase();
    this.pluginVimeo = true;
    this.videoData = this.content.videoData;
    this.ytEmbedUrl = this.content.ytEmbedUrl;
    this.imageData = this.content.imageData;
    this.blogImage = this.content.imageData;
    this.vimeoId = this.content.vimeoId;
    this.assistant = this.content.assistant;
    this.videoDraft = this.content.draftvideo;
  }
};
