import axios from "axios";
import {
  helpers,
  maxLength,
  maxValue,
  required,
  requiredIf
} from "vuelidate/lib/validators";
import axiosCall from "../../api/axios";
import AddContentPreview from "../../components/AddContentPreview/AddContentPreview.vue";
import Loader from "../../components/Loader/Loader.vue";
import PopUp from "../../components/PopUp/PopUp.vue";
import SideMenu from "../../components/SideMenu/SideMenu.vue";
import SubscribeFeedback from "../../components/Subscribe-FeedBack/SubscribeFeedback.vue";
import Tiptap from "../../components/Tiptap/Tiptap.vue";

const minPrice = value => value > 0 || !helpers.req(value);
export default {
  name: "AddContent",
  components: {
    SideMenu,
    PopUp,
    Loader,
    AddContentPreview,
    Tiptap,
    SubscribeFeedback
  },
  data() {
    return {
      subscribe: false,
      feedback: false,
      video: "",
      ebook: "",
      tooltip: false,
      draftvideo: false,
      price: { amount: null },
      pricetoggle: null,
      editprice: null,
      editpaid: false,
      paid: false,
      ytEmbedUrl:"",
      amount: 0,
      urlStorage: null,
      categoriesObj: [],
      mimeError: false,
      ebookMimeError: false,
      title: "",
      catName: null,
      catId: null,
      updatePublish: false,
      pluginVimeo: null,
      vimeoId: null,
      inputTagList: [],
      preview: false,
      invalidContent: false,
      blogContent: "",
      imageFileName: "",
      imageFile: null,
      videoFileName: "",
      videoFile: null,
      description: "",
      childEl: "",
      callBackdraft: false,
      callBackReview: false,
      content: undefined,
      categories: {},
      imageData: null,
      videoData: "",
      ebookData: null,
      tagList: [],
      beforeEditContent: null,
      contentType: this.$route.params.contentType,
      otherCategoryText: "",
      categoryText: "",
      otherCategory: false,
      categoryId: null,
      getAlertDialogStatus: false,
      counter: 0,
      getLoader: false,
      draftMessageStatus: false,
      message: "",
      subscription: "FREE",
      draft: false,
      // videoUploaded: false,
      ebookUploaded: false,
      presignedResponse: null,
      window: {
        width: 0,
        height: 0
      },
      inputs: [
        {
          tag: ""
        }
      ],
      name: {
        type: String,
        required: false,
        default: "dropdown",
        note: "Input name"
      },
      options: {
        type: Array,
        required: true,
        default: [],
        note: "Options of dropdown. An array of options with id and name"
      },
      placeholder: {
        type: String,
        required: false,
        default: "Select",
        note: "Placeholder of dropdown"
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
        note: "Disable the dropdown"
      },
      maxItem: {
        type: Number,
        required: false,
        default: 4,
        note: "Max items showing"
      },
      selected: {},
      optionsShown: false,
      searchFilter: null,
      sizeInKB: 0,
      videoSignedUrl: "",
      ebookSignedUrl: null,
      preSignedUrls: [],
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      }
    };
  },
  props: ["edit", "status", "contentid", "contentObject"],
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.edit == true) {
      if (this.contentObject.categories == null) {
        this.catName = null;
        this.catId = null;
      } else {
        this.catName = this.contentObject.categories[0].name;
        this.catId = this.contentObject.categories[0].id;
      }
      if (this.contentObject.subscriptionType == "FREE") {
        this.editpaid = false;
        this.price = this.contentObject.price;
      } else {
        this.editpaid = true;
        this.price = this.contentObject.price;
      }
      if (this.contentObject.storageUrl == null) {
        this.contentObject.storageUrl = "";
      }
      this.title = this.contentObject.title;
      this.imageData = this.contentObject.coverImageUrl;
      this.description = this.contentObject.description;
      this.searchFilter = this.catName;
      this.categoryId = this.catId;
      this.tagList = this.contentObject.tags;
      if (this.tagList.length != 0) {
        this.inputs = [];
      }
      for (var i = 0; i < this.tagList.length; i++) {
        var x = {
          tag: this.tagList[i]
        };
        this.inputs.push(x);
      }
      if (this.contentType == "VIDEO") {
        this.contentObject.contentData = "";
        this.videoData = this.ytEmbedUrl!=""?this.ytEmbedUrl:this.contentObject.storageUrl;
        this.ebookData = this.contentObject.contentUrl;
        if (this.videoData != "") {
          this.pluginVimeo = true;
        }
      }
      if (this.contentType == "EBOOK") {
        this.contentObject.contentData = "";
        this.ebookData = this.contentObject.contentUrl;
        this.videoData = this.contentObject.storageUrl;
        if (this.ebookData) {
          this.ebookUploaded = true;
        } else {
          this.ebookUploaded = false;
        }
      }
    }
  },
  validations: {
    title: {
      required,
      maxLength: maxLength(100)
    },
    description: {
      required: requiredIf(function() {
        if (this.draft == false) {
          return true;
        } else {
          return false;
        }
      }),
      maxLength: maxLength(1000)
    },
    imageData: {
      required: requiredIf(function() {
        if (this.draft == false) {
          return true;
        } else {
          return false;
        }
      })
    },
    videoData: {
      required: requiredIf(function() {
        if (this.contentType == "VIDEO" && !this.draft == false) {
          return true;
        } else {
          return false;
        }
      })
    },
    ebookData: {
      required: requiredIf(function() {
        if (this.contentType == "EBOOK" && this.draft == false) {
          return true;
        } else {
          return false;
        }
      })
    },
    categoryId: {
      required: requiredIf(function() {
        if (this.draft == false) {
          return true;
        } else {
          return false;
        }
      })
    },
    searchFilter: {
      required: requiredIf(function() {
        if (this.draft == false) {
          return true;
        } else {
          return false;
        }
      })
    },
    sizeInKB: {
      maxValue: maxValue(500)
    },
    otherCategoryText: {
      required: requiredIf(function() {
        if (this.otherCategory == true && this.draft == false) {
          return true;
        } else {
          return false;
        }
      })
    },
    price: {
      amount: {
        required: requiredIf(function() {
          if (this.paid == true) {
            return true;
          } else {
            return false;
          }
        }),
        minPrice,
        hasTwoDecimals(amount) {
          const regex = /^\d*(\.\d{0,2})?$/;
          if ((regex.test(amount) && this.paid == true) || this.paid == false) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    updatePreview: function(e) {
      this.preview = e;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    entryFillVideoData(){
      if(this.ytEmbedUrl!=""){
        this.videoData=this.ytEmbedUrl;
        this.urlStorage="https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af";
      }else{
        this.videoData="";
        this.urlStorage="";
      }
    },
    checkForFalsy(){
      if(!this.ytEmbedUrl){
        this.videoData="";
        this.urlStorage="";
      }
    },
    updateSubscribe: function(e) {
      this.subscribe = e;
    },
    updateFeedback: function(e) {
      this.feedback = e;
    },
    addServiceRow(event) {
      event.preventDefault();
      this.inputs.push({
        tag: ""
      });
    },
    deleteServiceRow(event, index) {
      event.preventDefault();
      this.inputs.splice(index, 1);
    },
    togglePaidEbook() {
      this.paid = !this.paid;
      if (this.paid == true && this.pricetoggle != true) {
        this.price = {
          amount: null,
          currency: "USD"
        };
      }
      if (this.pricetoggle == true) {
        this.pricetoggle = null;
      }
      var ele = document.getElementById("checked");
    },
    showToolTip() {
      if (this.contentType == "EBOOK" && this.ebookData == null) {
        this.tooltip = true;
      } else {
        this.tooltip = false;
      }
    },
    hideToolTip() {
      this.tooltip = false;
    },
    prepareRequest() {
      this.tagList = [];
      let rgxOtherCategory = /<\/?script>/gi;
      if (this.paid == true) {
        this.subscription = "PAID";
        this.price = {
          amount: this.price.amount,
          currency: "USD"
        };
      } else {
        this.subscription = "FREE";
        this.price = {
          amount: 1,
          currency: "USD"
        };
      }
      if (this.contentType == "BLOG") {
        var tempCont = document.getElementsByClassName("editor-div");
        this.childEl = tempCont[0].getElementsByClassName(
          "ql-editor"
        )[0].innerHTML;
      }
      for (let i = 0; i < this.inputs.length; i++) {
        if (this.inputs[i].tag != "") {
          this.tagList.push(this.inputs[i].tag);
        }
      }
      this.tagList = [...new Set(this.tagList)];
      if (this.otherCategory == true) {
        this.categoryText = this.otherCategoryText.replace(
          rgxOtherCategory,
          ""
        );
        this.categoryId = "OTHERS";
      } else {
        this.categoryText = this.searchFilter.replace(rgxOtherCategory, "");
        this.categoryId = this.categoryId;
      }
      if (this.contentType == "VIDEO") {
        this.urlStorage = this.videoSignedUrl;
      }
      if (this.contentType == "EBOOK") {
        this.urlStorage = this.ebookSignedUrl;
      }
      let requestObject = {
        title: this.title.replace(rgxOtherCategory, ""),
        description: this.description.replace(rgxOtherCategory, ""),
        categories: [{ name: this.categoryText, categoryId: this.categoryId }],
        contentType: this.contentType,
        contentData: this.childEl.replace(rgxOtherCategory, ""),
        price: this.price,
        vimeoEmbedUrl:this.vimeoEmbedUrl,
        ytEmbedUrl:this.ytEmbedUrl,
        subscriptionType: this.subscription,
        storageUrl: this.ytEmbedUrl!=""?"https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af":this.urlStorage,
        coverImageUrl: this.signedUrl,
        tags: this.tagList,
        seoDataModel:{
          h1TagData: this.title.replace(rgxOtherCategory, ""),
          metaDescription: this.description.replace(rgxOtherCategory, ""),
          metaTitle: this.title.replace(rgxOtherCategory, ""),
          openGraphTags: {
          	ogDescription: this.description.replace(rgxOtherCategory, ""),
            ogImage: this.signedUrl,
            ogTitle: this.title.replace(rgxOtherCategory, ""),
            ogType: this.contentType,
          }
        }
      };
      if (localStorage.getItem("assistant") == "true") {
        requestObject = {
          title: this.title.replace(rgxOtherCategory, ""),
          description: this.description.replace(rgxOtherCategory, ""),
          categories: [
            { name: this.categoryText, categoryId: this.categoryId }
          ],
          contentType: this.contentType,
          contentData: this.childEl.replace(rgxOtherCategory, ""),
          subscriptionType: this.subscription,
          price: this.price,
          storageUrl: this.ytEmbedUrl!=""?"https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af":this.urlStorage,
          ytEmbedUrl:this.ytEmbedUrl,
          coverImageUrl: this.signedUrl,
          tags: this.tagList,
          influencerId: localStorage.getItem("influencerId"),
          seoDataModel:{
            h1TagData: this.title.replace(rgxOtherCategory, ""),
            metaDescription: this.description.replace(rgxOtherCategory, ""),
            metaTitle: this.title.replace(rgxOtherCategory, ""),
            openGraphTags: {
              ogDescription: this.description.replace(rgxOtherCategory, ""),
              ogImage: this.signedUrl,
              ogTitle: this.title.replace(rgxOtherCategory, ""),
              ogType: this.contentType,
            }
          }
        };
      }
      return requestObject;
    },
    updateContentRequest() {
      this.tagList = [];
      let rgxOtherCategory = /<\/?script>/gi;
      if (this.paid == true) {
        this.subscription = "PAID";
        this.price = {
          amount: this.price.amount,
          currency: "USD"
        };
      } else {
        this.subscription = "FREE";
        this.price = {
          amount: 1,
          currency: "USD"
        };
      }
      if (this.contentType == "BLOG") {
        var tempCont = document.getElementsByClassName("editor-div");
        this.childEl = tempCont[0].getElementsByClassName(
          "ql-editor"
        )[0].innerHTML;
      }
      for (let i = 0; i < this.inputs.length; i++) {
        if (this.inputs[i].tag != "") {
          this.tagList.push(this.inputs[i].tag);
        }
      }
      this.tagList = [...new Set(this.tagList)];
      if (this.otherCategory == true) {
        this.categoryText = this.otherCategoryText.replace(
          rgxOtherCategory,
          ""
        );
        this.categoryId = "OTHERS";
      } else {
        if (this.searchFilter != null) {
          this.categoryText = this.searchFilter.replace(rgxOtherCategory, "");
          this.categoryId = this.categoryId;
        }
      }
      if (this.categoryId == null) {
        this.categoriesObj = [];
      } else {
        this.categoriesObj = [
          { name: this.categoryText, categoryId: this.categoryId }
        ];
      }
      if (this.contentType == "VIDEO") {
        this.urlStorage = this.videoSignedUrl;
      }
      if (this.contentType == "EBOOK") {
        this.urlStorage = this.ebookSignedUrl;
      }
      let updateRequestObject = {
        title: this.title.replace(rgxOtherCategory, ""),
        description: this.description.replace(rgxOtherCategory, ""),
        categories: this.categoriesObj,
        contentType: this.contentType,
        contentData: this.childEl.replace(rgxOtherCategory, ""),
        subscriptionType: this.subscription,
        storageUrl: this.ytEmbedUrl?"https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af":this.urlStorage,
        price: this.price,
        ytEmbedUrl:this.ytEmbedUrl,
        coverImageUrl: this.signedUrl,
        tags: this.tagList,
        id: this.contentid,
        seoDataModel:{
          h1TagData: this.title.replace(rgxOtherCategory, ""),
          metaDescription: this.description.replace(rgxOtherCategory, ""),
          metaTitle: this.title.replace(rgxOtherCategory, ""),
          openGraphTags: {
          	ogDescription: this.description.replace(rgxOtherCategory, ""),
            ogImage: this.signedUrl,
            ogTitle: this.title.replace(rgxOtherCategory, ""),
            ogType: this.contentType,
          }
        }
      };
      if (localStorage.getItem("assistant") == "true") {
        updateRequestObject = {
          title: this.title.replace(rgxOtherCategory, ""),
          description: this.description.replace(rgxOtherCategory, ""),
          categories: this.categoriesObj,
          contentType: this.contentType,
          contentData: this.childEl.replace(rgxOtherCategory, ""),
          subscriptionType: this.subscription,
          price: this.price,
          storageUrl: this.ytEmbedUrl!=""?"https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af":this.urlStorage,
          ytEmbedUrl:this.ytEmbedUrl,
          coverImageUrl: this.signedUrl,
          tags: this.tagList,
          id: this.contentid,
          influencerId: localStorage.getItem("influencerId"),
          seoDataModel:{
            h1TagData: this.title.replace(rgxOtherCategory, ""),
            metaDescription: this.description.replace(rgxOtherCategory, ""),
            metaTitle: this.title.replace(rgxOtherCategory, ""),
            openGraphTags: {
              ogDescription: this.description.replace(rgxOtherCategory, ""),
              ogImage: this.signedUrl,
              ogTitle: this.title.replace(rgxOtherCategory, ""),
              ogType: this.contentType,
            }
          }
        };
      }
      return updateRequestObject;
    },
    previewBeforeUpload: function() {
      if (this.contentType != "EBOOK") {
        if (this.paid == true) {
          this.subscription = "PAID";
          this.price = {
            amount: this.price.amount,
            currency: "USD"
          };
        } else {
          this.subscription = "FREE";
          this.price = {
            amount: 1,
            currency: "USD"
          };
        }
        if (this.contentType == "VIDEO" && this.edit == true) {
          if (this.videoData == this.contentObject.storageUrl) {
            if (this.videoData != "") {
              this.draftvideo = true;
            }
          } else {
            this.draftvideo = false;
          }
        }
        if (this.contentType == "BLOG") {
          var tempCont = document.getElementsByClassName("editor-div");
          this.childEl = tempCont[0].getElementsByClassName(
            "ql-editor"
          )[0].innerHTML;
        }
        let rgxOtherCategory = /<\/?script>/gi;
        this.tagList = [];
        for (let i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].tag != "") {
            this.tagList.push(this.inputs[i].tag);
          }
        }
        this.tagList = [...new Set(this.tagList)];
        if (this.otherCategory == true) {
          this.categoryText = this.otherCategoryText.replace(
            rgxOtherCategory,
            ""
          );
          this.categoryId = "OTHERS";
        } else {
          if (this.searchFilter != null) {
            this.categoryText = this.searchFilter.replace(rgxOtherCategory, "");
          }
          this.categoryId = this.categoryId;
        }
        if (this.contentType == "VIDEO") {
          this.urlStorage = this.videoSignedUrl;
        }
        if (this.contentType == "EBOOK") {
          this.urlStorage = this.ebookSignedUrl;
        }
        this.previewContent = {
          title: this.title.replace(rgxOtherCategory, ""),
          description: this.description.replace(rgxOtherCategory, ""),
          categories: [
            { name: this.categoryText, categoryId: this.categoryId }
          ],
          contentType: "VIDEO",
          contentData: this.childEl.replace(rgxOtherCategory, ""),
          subscriptionType: this.subscription,
          ytEmbedUrl:this.ytEmbedUrl,
          storageUrl: this.urlStorage,
          coverImageUrl: this.signedUrl,
          tags: this.tagList,
          price: this.price,
          videoData: this.videoData,
          imageData: this.imageData,
          vimeoId: this.vimeoId,
          draftvideo: this.draftvideo,
          assistant: localStorage.getItem("assistant")
        };
        this.preview = true;
        return this.previewContent;
      } else {
        if (this.ebookFile != null && this.ebookUploaded === false) {
          var url = URL.createObjectURL(this.ebookFile);
          window.open(url, "_blank");
        }
        if (this.ebookUploaded === true) {
          window.open(this.ebookData, "_blank");
        }
      }
    },
    cancelSubmission() {
      this.title = "";
      this.imageData = "";
      this.videoData = "";
      this.description = "";
      this.categories = {};
      this.searchFilter = "";
      this.otherCategoryText = "";
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseVideo(e) {
      e.stopPropagation();
      this.$refs.fileInputVideo.click();
    },
    chooseEbook(e) {
      e.stopPropagation();
      this.$refs.fileInputEbook.click();
    },
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      var file = e.dataTransfer.files;
      if (file && file[0]) {
        if (
          file[0]["type"] === "image/jpeg" ||
          file[0]["type"] === "image/png"
        ) {
          const reader = new FileReader();
          reader.onload = e => {
            this.imageData = e.target.result;
          };
          reader.readAsDataURL(file[0]);
          this.imageFile = file[0];
          this.getSize();
        } else {
        }
      }
    },
    onVideoDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      var file = e.dataTransfer.files;
      if (file && file[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.videoData = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.videoFile = file[0];
        this.video = file[0].name;
        this.customValidations(this.video);
        this.pluginVimeo = false;
      }
    },
    onEbookDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      var file = e.dataTransfer.files;
      if (file && file[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.ebookData = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.ebookUploaded = false;
        this.ebookFile = file[0];
        this.ebook = file[0].name;
        this.customValidations(this.ebook);
      }
    },

    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.imageFile = files[0];
        this.getSize();
      }
    },

    onVideoFile() {
      const inputVideo = this.$refs.fileInputVideo;
      const videoFiles = inputVideo.files;
      if (videoFiles && videoFiles[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.videoData = e.target.result;
        };
        reader.readAsDataURL(videoFiles[0]);
        this.videoFile = videoFiles[0];
        this.video = videoFiles[0].name;
        this.customValidations(this.video);
        this.pluginVimeo = false;
      }
    },
    onEbookFile() {
      const inputEbook = this.$refs.fileInputEbook;
      const ebookFiles = inputEbook.files;
      if (ebookFiles && ebookFiles[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.ebookData = e.target.result;
        };
        reader.readAsDataURL(ebookFiles[0]);
        this.ebookUploaded = false;
        this.ebookFile = ebookFiles[0];
        this.ebook = ebookFiles[0].name;
        this.customValidations(this.ebook);
      }
    },
    customValidations: function(mimeFileName) {
      let validFilesTypes = ["mp4", "avi", "mpg", "m4v", "mov"];
      let ebookValidFilesTypes = ["pdf"];
      let uploadVideoExtension = mimeFileName.toLowerCase().split(".")[1];
      if (this.contentType == "VIDEO") {
        if (!validFilesTypes.includes(uploadVideoExtension)) {
          this.mimeError = true;
        } else {
          this.mimeError = false;
        }
      }
      if (this.contentType == "EBOOK") {
        if (!ebookValidFilesTypes.includes(uploadVideoExtension)) {
          this.ebookMimeError = true;
        } else {
          this.ebookMimeError = false;
        }
      }
    },
    getSize() {
      this.sizeInKB = parseFloat(this.imageFile.size / 1024).toFixed(2);
    },
    getStorageUrl() {
      this.preSignedUrls = [];
      if (this.videoFile != null && this.videoFile != undefined) {
        let videoRequest = {
          mimeType: this.videoFile.type,
          numberOfPreSignedURLsRequired: 1,
          preSignedURLSource: "VIDEO"
        };
        this.preSignedUrls.push(videoRequest);
      }
      if (this.ebookFile != null && this.ebookFile != undefined) {
        let ebookRequest = {
          mimeType: this.ebookFile.type,
          numberOfPreSignedURLsRequired: 1,
          preSignedURLSource: "EBOOK"
        };
        this.preSignedUrls.push(ebookRequest);
      }
      if (this.imageFile != null && this.imageFile != undefined) {
        let imageRequest = {
          mimeType: this.imageFile.type,
          numberOfPreSignedURLsRequired: 1,
          preSignedURLSource: "IMAGE"
        };
        this.preSignedUrls.push(imageRequest);
      }
      if (this.updatePublish != true) {
        this.getPreSignedUrl();
      } else {
        this.draftCallBack();
      }
    },

    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = "";
        var i;
        var ul = document.getElementsByClassName("dropdown-content");
        ul[0].style.height = "6em";
        var li = ul[0].getElementsByClassName("dropdown-item");
        for (i = 0; i < li.length; i++) {
          li[i].style.display = "block";
        }
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = "";
        this.disabled = false;
      } else {
        this.searchFilter = this.selected.name;
        this.categoryId = this.selected.id;
        if (this.searchFilter == "Other") {
          this.otherCategory = true;
        } else {
          this.otherCategory = false;
        }
      }
      this.optionsShown = false;
    },
    keyMonitor: function(event) {
      var categoryInput, filter, ul, li, a, i, txtValue;
      var countCategories = 0;
      categoryInput = document.getElementById("search-category");
      filter = categoryInput.value.toUpperCase();
      ul = document.getElementsByClassName("dropdown-content");
      li = ul[0].getElementsByClassName("dropdown-item");
      for (i = 0; i < li.length; i++) {
        txtValue = li[i].textContent || li[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "block";
          countCategories += 1;
        } else {
          li[i].style.display = "none";
        }
      }
      if (countCategories == 2) {
        ul[0].style.height = "76px";
        ul[0].style.display = "block";
      } else if (countCategories == 1) {
        ul[0].style.height = "38px";
        ul[0].style.display = "block";
      } else if (countCategories == 0) {
        ul[0].style.display = "none";
      } else {
        ul[0].style.height = "6em";
        ul[0].style.display = "block";
      }
    },

    submit() {
      this.draft = false;
      window.scrollTo(0, 0);
      if (this.contentType == "BLOG") {
        var tempCont = document.getElementsByClassName("editor-div");
        this.childEl = tempCont[0].getElementsByClassName(
          "ql-editor"
        )[0].innerHTML;
        if (
          tempCont[0].getElementsByClassName("ql-editor")[0].innerText.trim()
            .length == 0
        ) {
          this.invalidContent = true;
        } else {
          this.invalidContent = false;
        }
      }
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        this.mimeError == false &&
        this.ebookMimeError == false
      ) {
        if (
          (this.contentType == "BLOG" && this.invalidContent == false) ||
          this.contentType != "BLOG"
        ) {
          this.getStorageUrl();
          this.callBackdraft = false;
          this.callBackReview = true;
        }
      }
    },
    reviewCallBack() {
      if (this.edit != true) {
        let requestObject = this.prepareRequest();
        this.getLoader = true;

        axiosCall.postRequest(
          process.env.VUE_APP_HOST + "content/api/v1/influencer",
          requestObject,
          this.callback
        );
      }
      if (this.edit == true) {
        if (this.status != "DRAFT") {
          if (this.signedUrl == undefined) {
            this.signedUrl = this.contentObject.coverImageUrl;
          }
          if (this.videoSignedUrl == "") {
            this.videoSignedUrl = this.contentObject.contentUrl;
          }
          if (this.ebookSignedUrl == null) {
            this.ebookSignedUrl = this.contentObject.contentUrl;
          }
          let requestObject = this.updateContentRequest();
          this.getLoader = true;

          axiosCall.putRequest(
            process.env.VUE_APP_HOST + "content/api/v1/influencer",
            this.callback,
            requestObject
          );
        }
        if (this.status == "DRAFT") {
          if (this.signedUrl == undefined) {
            this.signedUrl = this.contentObject.coverImageUrl;
          }
          if (this.videoSignedUrl == "") {
            this.videoSignedUrl = this.contentObject.storageUrl;
          }
          if (this.ebookSignedUrl == null) {
            this.ebookSignedUrl = this.contentObject.contentUrl;
          }
          if (this.contentType == "BLOG") {
            var tempCont = document.getElementsByClassName("editor-div");
            this.childEl = tempCont[0].getElementsByClassName(
              "ql-editor"
            )[0].innerHTML;
          }
          if (
            this.title == this.contentObject.title &&
            this.imageData == this.contentObject.coverImageUrl &&
            this.videoData == this.contentObject.storageUrl &&
            this.ebookData == this.contentObject.contentUrl &&
            this.description == this.contentObject.description &&
            this.searchFilter == this.contentObject.categories[0].name &&
            this.childEl == this.contentObject.contentData &&
            this.tagList == this.contentObject.tags &&
            this.paid == this.editpaid &&
            this.price == this.contentObject.price
          ) {
            this.$v.$touch();
            if (this.contentType == "BLOG") {
              var tempCont = document.getElementsByClassName("editor-div");
              this.childEl = tempCont[0].getElementsByClassName(
                "ql-editor"
              )[0].innerHTML;
              if (
                tempCont[0]
                  .getElementsByClassName("ql-editor")[0]
                  .innerText.trim().length == 0
              ) {
                this.invalidContent = true;
              } else {
                this.invalidContent = false;
              }
            }
            if (
              !this.$v.$invalid &&
              this.mimeError == false &&
              this.ebookMimeError == false
            ) {
              if (
                (this.contentType == "BLOG" && this.invalidContent == false) ||
                this.contentType != "BLOG"
              ) {
                this.publishDraft(this.contentid);
              }
            }
          } else {
            this.updatePublish = true;
            this.saveDraft();
          }
        }
      }
    },
    callback(response, type) {
      if (type === "success") {
        if (response.data.apiResponseStatus.code === 1000) {
          this.draftMessageStatus = false;
          this.getLoader = false;
          this.getAlertDialogStatus = true;
          this.message =
            "Your data has been saved and sent for moderator approval";
        }
      }
    },
    publishDraft(contentid) {
      this.getLoader = true;
      let payload = {};
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");

        axiosCall.postRequest(
          process.env.VUE_APP_HOST +
            "content/api/v1/influencer/draft/publish/" +
            contentid +
            "?influencerId=" +
            inflncrId,
          payload,
          this.callback
        );
      } else {
        axiosCall.postRequest(
          process.env.VUE_APP_HOST +
            "content/api/v1/influencer/draft/publish/" +
            contentid,
          payload,
          this.callback
        );
      }
    },
    getPreSignedUrl: function() {
      var portalHeaders = {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      };
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        return axiosCall.postRequest(
          process.env.VUE_APP_HOST +
            `content/api/v1/influencer/presignedurl` +
            "?influencerId=" +
            inflncrId,
          this.preSignedUrls,
          this.callbackPresigned
        );
      } else {
        return axiosCall.postRequest(
          process.env.VUE_APP_HOST + `content/api/v1/influencer/presignedurl`,
          this.preSignedUrls,
          this.callbackPresigned
        );
      }
    },
    callbackPresigned(response, type) {
      if (type === "success") {
        this.presignedResponse = response;
        if (this.videoFile != null) {
          this.videoSignedUrl =
            response.data.responseObject[0].preSignedUrls[0];
          if (this.imageFile != null) {
            this.signedUrl = response.data.responseObject[1].preSignedUrls[0].split(
              "?"
            )[0];
            this.uploadToSignedUrl(
              response.data.responseObject[1].preSignedUrls[0],
              this.imageFile
            );
            this.sizeInKB = parseFloat(this.imageFile.size / 1024).toFixed(2);
          }
        } else {
          if (this.ebookFile != null) {
            this.ebookSignedUrl =
              response.data.responseObject[0].preSignedUrls[0];
            if (this.imageFile != null) {
              this.signedUrl = response.data.responseObject[1].preSignedUrls[0].split(
                "?"
              )[0];
              this.uploadToSignedUrl(
                response.data.responseObject[1].preSignedUrls[0],
                this.imageFile
              );
              this.sizeInKB = parseFloat(this.imageFile.size / 1024).toFixed(2);
            }
          } else {
            if (this.imageFile != null) {
              this.signedUrl = response.data.responseObject[0].preSignedUrls[0].split(
                "?"
              )[0];
              this.uploadToSignedUrl(
                response.data.responseObject[0].preSignedUrls[0],
                this.imageFile
              );
              this.sizeInKB = parseFloat(this.imageFile.size / 1024).toFixed(2);
            }
          }
        }
        if (this.callBackdraft == true) {
          this.getLoader = true;
          if (this.videoFile != null) {
            this.uploadToSignedUrl(this.videoSignedUrl, this.videoFile).then(
              response => {
                this.draftCallBack();
              }
            );
          } else {
            if (this.ebookFile != null) {
              this.uploadToSignedUrl(this.ebookSignedUrl, this.ebookFile).then(
                response => {
                  this.draftCallBack();
                }
              );
            } else {
              this.draftCallBack();
            }
          }
        }
        if (this.callBackReview == true) {
          this.getLoader = true;
          if (this.videoFile != null) {
            this.uploadToSignedUrl(this.videoSignedUrl, this.videoFile).then(
              response => {
                this.reviewCallBack();
              }
            );
          } else {
            if (this.ebookFile != null) {
              this.uploadToSignedUrl(this.ebookSignedUrl, this.ebookFile).then(
                response => {
                  this.reviewCallBack();
                }
              );
            } else {
              this.reviewCallBack();
            }
          }
        }
      }
    },
    uploadToSignedUrl: function(signedUrl, file) {
      let headers1 = {
        "Content-Type": file.type,
        Accept: "text/plain, */*; q=0.01"
      };
      return axios.put(signedUrl, file, { headers: headers1 });
      // axios.put(signedUrl, file, { headers: headers1 });
      // axiosCall.putRequest(signedUrl,null,file,{},headers1)
    },

    saveDraft() {
      if (this.updatePublish != true) {
        this.draft = true;
      }
      window.scrollTo(0, 0);
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        this.mimeError == false &&
        this.ebookMimeError == false
      ) {
        this.getStorageUrl();
        this.callBackdraft = true;
        this.callBackReview = false;
      }
    },
    draftCallBack() {
      this.tagList = [];
      if (this.edit != true) {
        if (this.paid == true) {
          this.subscription = "PAID";
          this.price = {
            amount: this.price.amount,
            currency: "USD"
          };
        } else {
          this.subscription = "FREE";
          this.price = {
            amount: 1,
            currency: "USD"
          };
        }
        if (this.contentType == "BLOG") {
          var tempCont = document.getElementsByClassName("editor-div");
          this.childEl = tempCont[0].getElementsByClassName(
            "ql-editor"
          )[0].innerHTML;
        }
        for (let i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].tag != "") {
            this.tagList.push(this.inputs[i].tag);
          }
        }
        this.tagList = [...new Set(this.tagList)];
        let rgxOtherCategory = /<\/?script>/gi;
        if (this.otherCategory == true) {
          this.categoryText = this.otherCategoryText.replace(
            rgxOtherCategory,
            ""
          );
          this.categoryId = "OTHERS";
        } else {
          if (this.searchFilter != null) {
            this.categoryText = this.searchFilter.replace(rgxOtherCategory, "");
          }
          this.categoryId = this.categoryId;
        }
        if (this.categoryId == null) {
          this.categoriesObj = [];
        } else {
          this.categoriesObj = [
            { name: this.categoryText, categoryId: this.categoryId }
          ];
        }
        if (this.contentType == "VIDEO") {
          this.urlStorage = this.videoSignedUrl;
        }
        if (this.contentType == "EBOOK") {
          this.urlStorage = this.ebookSignedUrl;
        }

        let draftObject = {
          title: this.title.replace(rgxOtherCategory, ""),
          description: this.description.replace(rgxOtherCategory, ""),
          categories: this.categoriesObj,
          contentType: this.contentType,
          subscriptionType: this.subscription,
          ytEmbedUrl:this.ytEmbedUrl,
          contentData: this.childEl.replace(rgxOtherCategory, ""),
          storageUrl: this.ytEmbedUrl!=""?"https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af":this.urlStorage,
          price: this.price,
          coverImageUrl: this.signedUrl,
          tags: this.tagList,
          id: "",
          seoDataModel:{
            h1TagData: this.title.replace(rgxOtherCategory, ""),
            metaDescription: this.description.replace(rgxOtherCategory, ""),
            metaTitle: this.title.replace(rgxOtherCategory, ""),
            openGraphTags: {
              ogDescription: this.description.replace(rgxOtherCategory, ""),
              ogImage: this.signedUrl,
              ogTitle: this.title.replace(rgxOtherCategory, ""),
              ogType: this.contentType,
            }
          }
        };
        if (localStorage.getItem("assistant") == "true") {
          draftObject = {
            title: this.title.replace(rgxOtherCategory, ""),
            description: this.description.replace(rgxOtherCategory, ""),
            categories: this.categoriesObj,
            contentType: this.contentType,
            subscriptionType: this.subscription,
            price: this.price,
            ytEmbedUrl:this.ytEmbedUrl,
            contentData: this.childEl.replace(rgxOtherCategory, ""),
            storageUrl: this.ytEmbedUrl!=""?"https://leadershum.s3.amazonaws.com/DEV/99/VIDEO/dd75df2e-c91c-42c8-8cae-ff0e84c40259?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220624T051712Z&X-Amz-SignedHeaders=content-type%3Bhost&X-Amz-Expires=900&X-Amz-Credential=AKIA3AIID5DL7ZNHFDMN%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8e280534acc2a8ded144b03c5e294beba4e7fca13a09d6665dc896eba5abb6af":this.urlStorage,
            coverImageUrl: this.signedUrl,
            tags: this.tagList,
            id: "",
            influencerId: localStorage.getItem("influencerId"),
            seoDataModel:{
              h1TagData: this.title.replace(rgxOtherCategory, ""),
              metaDescription: this.description.replace(rgxOtherCategory, ""),
              metaTitle: this.title.replace(rgxOtherCategory, ""),
              openGraphTags: {
                ogDescription: this.description.replace(rgxOtherCategory, ""),
                ogImage: this.signedUrl,
                ogTitle: this.title.replace(rgxOtherCategory, ""),
                ogType: this.contentType,
              }
            }
          };
        }
        this.getLoader = true;
        axiosCall.postRequest(
          process.env.VUE_APP_HOST + "content/api/v1/influencer/draft",
          draftObject,
          this.callback
        );
      }
      if (this.edit == true) {
        if (this.status != "IN_REVIEW") {
          if (this.signedUrl == undefined) {
            this.signedUrl = this.contentObject.coverImageUrl;
          }
          if (this.videoSignedUrl == "") {
            this.videoSignedUrl = this.contentObject.storageUrl;
          }
          if (this.ebookSignedUrl == null) {
            this.ebookSignedUrl = this.contentObject.contentUrl;
          }
          let requestObject = this.updateContentRequest();
          this.getLoader = true;
          axiosCall.postRequest(
            process.env.VUE_APP_HOST + "content/api/v1/influencer/draft",
            requestObject,
            this.callbackDraft
          );
        }
      }
    },
    callbackDraft(response, type) {
      if (type === "success") {
        if (response.data.apiResponseStatus.code === 1000) {
          if (this.updatePublish != true) {
            this.draftMessageStatus = false;
            this.getLoader = false;
            this.getAlertDialogStatus = true;
            this.message =
              "Your data has been saved and can be viewed in the details page";
          } else {
            this.$v.$touch();
            if (this.contentType == "BLOG") {
              var tempCont = document.getElementsByClassName("editor-div");
              this.childEl = tempCont[0].getElementsByClassName(
                "ql-editor"
              )[0].innerHTML;
              if (
                tempCont[0]
                  .getElementsByClassName("ql-editor")[0]
                  .innerText.trim().length == 0
              ) {
                this.invalidContent = true;
              } else {
                this.invalidContent = false;
              }
            }
            if (
              !this.$v.$invalid &&
              this.mimeError == false &&
              this.ebookMimeError == false
            ) {
              if (
                (this.contentType == "BLOG" && this.invalidContent == false) ||
                this.contentType != "BLOG"
              ) {
                this.publishDraft(this.contentid);
              } else {
                this.getLoader = false;
              }
            } else {
              this.getLoader = false;
            }
          }
        }
      }
    }
  },

  mounted() {
    if (this.edit == true && this.contentType == "BLOG") {
      var tempCont = document.getElementsByClassName("ql-editor");
      tempCont[0].innerHTML = this.contentObject.contentData;
    }
    if (this.edit == true && this.contentType == "EBOOK") {
      var ele = document.getElementById("checked");
      if (this.contentObject.subscriptionType == "PAID") {
        this.pricetoggle = true;
        document.getElementById("checked").click();
      }
    }

    axiosCall.getRequest(
      process.env.VUE_APP_HOST + "content/api/v1/category",
      (response, type) => {
        if (type === "success") {
          this.categories = response.data.responseObject;
          if (response.data) {
            this.options = this.categories;
            this.options = true;
            this.disabled = false;
          }
        }
      }
    );
  }
};
