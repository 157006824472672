import { initFacebookSdk } from "../../config/facebook_oAuth.js";
import { mapGetters, mapActions } from "vuex";
import {
  required,email,requiredIf
} from "vuelidate/lib/validators";
import axiosCall from "../../api/axios";
import axios from "axios";

import Loader from "../Loader/Loader.vue";

export default {
  name: "LogIn",
  components: {
    Loader
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      successMessageStatus: false,
      errorMessageStatus: false,
      successMessage: "",
      errorMessage: "",
      loginStatus: true,
      inviteStatus:false,
      isLoading: false,
      hidePassword: false,
      showPassword: false,
      signupError: "",
      foundError: false,
      foundSignUpError: false,
      setIsDisabled: false,
    };
  },

  validations: {
    email: { required, email },
    password:{required,isPasswordValid(password) {
          const regex = /^(?=.*[A-Z])[A-Za-z\d.@$!%*?_&]{6,24}$/;
          if (regex.test(password)) {
            return true;
          } else {
            return false;
          }
    }
    },
    firstName: { required: requiredIf(function() {
        if (!this.loginStatus) {
          return true;
        } else {
          return false;
        }
      }) },
    lastName:{required: requiredIf(function() {
        if (!this.loginStatus) {
          return true;
        } else {
          return false;
        }
      })}
    
  },

  created() {
    if (window.location.pathname=== "/signup") {
      this.loginStatus = false;
      this.inviteStatus = false;
    }
    else if (window.location.pathname === "/signup/invite") {
      this.loginStatus = false;
      this.inviteStatus = true;
    }
    else {
      this.loginStatus = true;
      this.inviteStatus = false;
		}
  },

  mounted() {
    window.scrollTo(0, 0);
    initFacebookSdk();
  },
  computed: {
    ...mapGetters({
      getErrorMessage: "getErrorMessage",
      getErrorAuthenticate: "getErrorAuthenticate"
    }),
    isDisabled: function(){
    	return !this.setIsDisabled;
    }
  },

  methods: {
    // ...mapActions('main',['setEmail']),
    toggle() {
      this.showPassword = !this.showPassword;
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#password");

      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },

    loginWithEmail() {
      let verifyObject = {
        loginSource: "Email",
        logonId: this.email,
        password: this.password,
        oauthUri: "",
        accessToken: ""
      };
      return verifyObject;
    },

    loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          const googleAuthResponse = GoogleUser.getAuthResponse();

          let sendRequest = {
            loginSource: "Google",
            logonId: GoogleUser.getBasicProfile().getEmail(),
            password: "",
            oauthUri: "",
            accessToken: googleAuthResponse.id_token
          };

          this.$store.dispatch("authenticate", sendRequest);
        })
        .catch(error => {
          var c = error;
        });
    },

    loginWithLinkedIn() {
      this.$store.dispatch("loginWithLinkedIn");
    },

    loginWithFacebook() {
      window.FB.login(
        response => {
          if (response) {
            var url = "/me?fields=name,email";
            window.FB.api(url, emailIdresponse => {
              const fbResponse = response;
              const emailIdResponse = emailIdresponse;
              let fbSendRequest = {
                loginSource: "Facebook",
                logonId: emailIdResponse.email,
                password: "",
                oauthUri: "",
                accessToken: fbResponse.authResponse.accessToken
              };

              this.$store.dispatch("authenticate", fbSendRequest);
            });
          } else {
          }
        },
        { scope: "email" }
      );
    },

    customValidation() {
      this.foundError = false;
      if (!this.email || !this.password) {
        this.customErrorStatus = true;
        this.foundError = true;
      }
      return this.foundError ? false : true;
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.customValidation() && !this.$v.$invalid) {
        let verifyObject = this.loginWithEmail();
        this.$store.dispatch("authenticate", verifyObject);
      }
    },

    //signUp

    signUpValidation() {
      this.foundSignUpError = false;
      if (
        (!this.email && !this.password) ||
        (!this.firstName && !this.lastName)
      ) {
        this.foundSignUpError = true;
        this.successMessageStatus = false;
        this.errorMessageStatus = false;
      }
      return this.foundSignUpError ? false : true;
    },

    createAccount() {
      this.$v.$touch();
      if (this.signUpValidation() && !this.$v.$invalid) {
        this.isLoading = true;
        let registerObject = this.prepareRequestObject();
        let url = '';
        if (this.inviteStatus) {
          url = process.env.VUE_APP_HOST + "account/api/v1/register-replica?replicaPage=true";
        }
        else {
          url=process.env.VUE_APP_HOST + "account/api/v1/register";
        }

        axiosCall.postRequest(
          url,
          registerObject,
          (response)=> {
              if (response.data.apiResponseStatus.code === 1000) {
                this.successMessageStatus = true;
                this.errorMessageStatus = false;
                this.foundSignUpError = false;
                if (!this.inviteStatus) {
                  this.successMessage =
                    "Registration Successful. Go to your registered email to verify your Email-Id";
                }
                else {
                  let response1 = response.data.responseObject;
                  localStorage.setItem(
                    "access_token",
                    response1.oauth2AccessToken.access_token
                  );
                  localStorage.setItem(
                    "refresh_token",
                    response1.oauth2AccessToken.refresh_token
                  );
                  localStorage.setItem(
                    "expires_in",
                    response1.oauth2AccessToken.expires_in
                  );

                  let d = new Date();
                  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                  let expires = "expires=" + d.toUTCString();

                  document.cookie =
                    "AccessToken" +
                    "=" +
                    localStorage.getItem("access_token") +
                    ";" +
                    expires +
                    ";domain=.peoplehum.com;path=/";
                  
                  this.$store.commit("setToken", response);
                  this.$store.commit("setErrorMessage", "");
                  this.$store.commit("setLoader", false);
                  this.$store.dispatch("getSession");
                  
                }
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this.errorMessageStatus = true;
                this.successMessageStatus = false;
                this.foundSignUpError = false;
                this.errorMessage = response.data.apiResponseStatus.message;
              }
          }
        );
      }
    },
    prepareRequestObject() {
      let signUpObject = {
        firstName: this.firstName,
        lastName: this.lastName,
        logonId: this.email,
        password: this.password
      };
      return signUpObject;
    },

    signup() {
      this.loginStatus = false;
      this.email = "";
      this.password = "";
    },

    termsAndConditions() {
      window.open("https://leadershum.s3.amazonaws.com/PROD/Influencer+agreement/Influencer+agreement+r1+(1).pdf", "_blank");
    }
  }
};
