import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Vuelidate from "vuelidate";
import "./main.scss";




import GoogleAuth from "./config/google_oAuth.js";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

Vue.use(VueCropper);

Vue.config.productionTip = false;
console.log(Vue.version);
console.log(process.env.VUE_APP_HOST);
console.log(process.env.VUE_APP_TEST_VAR);
console.log(process.env.VUE_APP_LINK);

const gauthOption = {
  clientId:
    "260158530747-h4gpru6naqjptg9l23hvp4gqb90eq8g3.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/userinfo.email",
  // ClientSecret: '0Nk13dYDNcQcQwvAxg7lGd7d',
  prompt: "select_account"
};
Vue.use(GoogleAuth, gauthOption);
Vue.use(Vuelidate);

const app = new Vue({
  router,
  store: store,
  el: "#app",
  render: h => h(App)
});
